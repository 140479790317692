import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const visitasPoints = [
  { id: "c766437aec1259dd98f4a99268b3d9e3", title: "Metalografía In Situ" },
  {
    id: "6751cbe47b4e68d3f474c58008158e35",
    title: "Medición de espesores de metal",
  },
  { id: "ab7e85dbca19d3aba553ddfbf8d32cbd", title: "Tintas penetrantes" },
  {
    id: "92b406a587cf26ca37ef2bde86300d55",
    title: "Adherencia de revestimientos y pinturas",
  },
  {
    id: "bda5e3d20e839308ac42c4567f6570cf",
    title: "Ensayos de porosidad de pintura",
  },
  { id: "78ebf857171d8c45c9630e983eee646f", title: "Dureza Leeb" },
  { id: "d9761c27d3ae4a6c21d388c613aa6b4c", title: "Peritajes de fallas" },
]

const CertificacionesAnalisisFalla = () => {
  return (
    <Layout location="/servicios">
      <SEO title="Visita a Terreno" />
      <div className="servicios-main-wrap">
        <div className="servicios-header py-3 visita">
          <div className="container">
            <div className="row align-items-center h-100">
              <div className="col-12">
                <h2 className="text-center title">Visita a Terreno</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios-content visita">
          <div className="content-background falla"></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul>
                  {visitasPoints.map(e => (
                    <li key={e.id}>{e.title}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CertificacionesAnalisisFalla
